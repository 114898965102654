const DEFAULT_BASE_URL = "https://go.nordvpn.net/aff_c";

const DEFAULT_OFFER_ID = 30;

export function getNordVpnAdLink() {
    let baseUrl = DEFAULT_BASE_URL;
    let offerId = DEFAULT_OFFER_ID;
    const urlParams = new URLSearchParams({
        aff_id: 30518,
        aff_sub: "ut",
        offer_id: offerId,
        url_id: 914,
    }).toString();
    const link = `${baseUrl}?${urlParams}`;
    return link;
}
