import React from "react"
import useTranslations from "src/utils/useTranslations"
import Logo from "src/components/logo"
import backgrounds from "src/components/backgrounds"
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BenchClient from "src/utils/benchClient";

const linkOsx = "https://download-hr.utorrent.com/track/stable/endpoint/utmac/os/osx"
const heightParentHeightRatio = 0.45;
const nordVpnLink = getNordVpnAdLink();

class DownloadsCompleteClassic extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			isChrome:false,
			isIE:false,
			isMac:true,
			fs:15
		}

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if(fs <= 12) {
			return
		}
		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})

		}
	}

	componentDidUpdate(prevProps, prevState){
		console.log(this.state.fs)
		console.log(prevState.fs)
		if(this.state.fs !== prevState.fs){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){

		this.adjustFs(this.state.fs)

		var dlLink = linkOsx

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome:true,
				isIE:false
			})
		}
		else if(window.gatsbyIsIE){
			this.setState({
				isChrome:false,
				isIE:true
			})
		}
		let benches = [BenchClient.ping('load.classic_download')];
		setTimeout(async ()=> {
			await Promise.all(benches);
			window.location = dlLink;
		}, 1);
	}

	render(){
		const { text, locale } = this.props.pageContext
		const t = useTranslations(text)

		if(locale === "en" || locale === "ja" || locale === "zh_tw"){
			var runInstaller = <li className="my-3">{t("Run")} <span className='font-weight-bold'>µTorrent.dmg</span> {t("to install")}</li>

			var tryAgain = <>
	      		<p className="text-gray ml-3" style={{...font.lg}}><b>{t(`* If your download does not start automatically, please`)}</b> <a href={`${linkOsx}`} className="">{t(`try again.`)}</a></p>
			</>
		}
		else{
			runInstaller = t(`Run µTorrent.dmg to install`)
			runInstaller = runInstaller.replace('µTorrent.dmg', `<span class='font-weight-bold'>µTorrent.dmg</span>`)
			runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>

			tryAgain = <>
	      		<p className="text-gray ml-3" style={{...font.lg}}> <a href={`${linkOsx}`} className="post-download-dark"><b>{t(`* If your download does not start automatically, please try again.`)}</b></a></p>
	      		<br/>
			</>
		}
		return (
			<div className="download-complete-container download-complete-container-light top-section-container d-flex align-items-center flex-column background-noise-light" style={backgrounds.lightBg}>
				<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
					<p className="text-dark p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...style.headerSpacing}}>{t("Thank you for downloading")}</p>
					<div className="row">
						<div className="col-lg-6 d-flex flex-column justify-content-center" style={{minWidth: '450px'}}>
							<Logo productName="Classic" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-left ml-3"/>
							<ul className="m-0 download-complete-list text-left" style={font.lg}>
								<li  className="my-3">{t("Wait for the download to finish*")}</li>
								{runInstaller}
								<li className="my-3">{t("µTorrent Classic will run once installed")}</li>
							</ul>
							{tryAgain}
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<a target="_blank" href={nordVpnLink} style={nordStyle}>
							</a>
						</div>
					</div>
			    </div>
			</div>
		)
	}
}

export default DownloadsCompleteClassic
const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};
const font = {
	lg:{
		fontSize:'2em',
	},
	md:{
		fontSize:'1.25em',
	}
}

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}